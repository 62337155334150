import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
} from "react-native";
import { Textarea, Thumbnail } from "native-base";
import config from "../../config";
import SIcon from "react-native-vector-icons/SimpleLineIcons";
import { connect } from "react-redux";
// import Dash from 'react-native-dash';
import { AirbnbRating } from "react-native-ratings";
import {
  loadGigDetails,
  scrollToggle,
  changeGigOrderStatus,
  changeGigMilestoneStatus,
  fundFullAmount,
  fundMilestone,
  updateRedo,
  overlayRedoToggle,
  refundOrder,
  hideAlert,
  submitReview,
  setActionPending,
} from "../../state-management/actions/gigdetails.action";
import { Modal } from "react-native-paper";
import FIcon from "react-native-vector-icons/Feather";
import GigMilestoneCard from "../../components/GigMilestoneCard";
import PrimaryButton from "../../components/AnimatedButton";
import RejectedButton from "../../components/RejectedButton";
import RedoOverlay from "../../components/RedoOverlay";
import TopicChat from "../../components/TopicChat";
import AIcon from "react-native-vector-icons/AntDesign";
import Pot from "../../assets/images/pot.svg";
import Box from "../../assets/images/box.svg";
// import Share from 'react-native-share';
import DeliveryDetails from "../../components/DeliveryDetails";
import ViewDeliveryDetails from "../../components/ViewDeliveryDetails";
import AwesomeAlert from "react-native-awesome-alerts";
import {
  setFloatingData,
  resetFloatingData,
} from "../../state-management/actions/floating.action";
import { loadProfile } from "../../state-management/actions/userprofile.action";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import TimelineComponent from "../../components/TimelineComponent";
import BIcon from "react-native-vector-icons/FontAwesome5";
import GetComplement from "../../assets/images/getComplement";
import ExcellentCommunicator from "../../assets/images/excellentCommunicator";
import FastDelivery from "../../assets/images/fastDelivery";
import GreatAttitude from "../../assets/images/greatAttitude";
import SixStarService from "../../assets/images/sixStarService";
import MiracleWorker from "../../assets/images/miracleWorker";
import imageService from "../../services/image.service";

const GigDetails = props => {
  const [redoDetails, setRedoDetails] = useState("");
  const [redoMilestoneId, setRedoMilestoneId] = useState("");
  const [review, setReview] = useState("");
  const [reviewSubmitted, setReviewSubmitted] = useState(true);
  const [alertConformStart, setAlertConfirmStart] = useState(false);
  const [alertConformComplete, setAlertConfirmComplete] = useState(false);
  const [alertConformCompleteVirtual, setAlertConfirmCompleteVirtual] =
    useState(false);
  const [tempUid, setTempUid] = useState("");
  const [tempStatus, setTempStatus] = useState("");
  const [alertFund, setAlertFund] = useState(false);
  const [alertAccept, setAlertAccept] = useState(false);
  const [alertBuyerAccept, setAlertBuyerAccept] = useState(false);
  const [rev1, setRev1] = useState(null);
  const [rev2, setRev2] = useState(null);
  const [deliveryDetailsModel, setDeliveryDetailsModel] = useState(false);
  const [viewDeliveryDetailsModel, setViewDeliveryDetailsModel] =
    useState(false);
  const [complementSelected, setComplementSelected] = useState(null);
  const [complementCodeSelected, setComplementCodeSelected] = useState(null);
  const [giveComplementClicked, setGiveComplementClicked] = useState(false);
  const [tempData, setTempData] = useState([]);
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "Details", title: "Details" },
    { key: "Chat", title: "Chat" },
  ]);
  const scrollindDown = useRef(null);
  const [reviewAttachment, setReviewAttachment] = useState("");

  useEffect(() => {
    props.resetFloatingData();
    props.loadProfile();
    props.loadGigDetails(props.route.params.uid);
    if (props.route.params.hasOwnProperty("initiallPage")) {
      setIndex(1);
    }
    if (props.route.params?.action == "funded") {
      alertFund(true);
    }
    switch (props.route.params?.action) {
      case "funded":
        setAlertFund(true);
        break;
      case "completed":
        setAlertAccept(true);
        break;
      case "delivered":
        setAlertBuyerAccept(true);
        break;
      default:
        break;
    }
    return () => {};
  }, []);

  const shareMessage = () => {
    // Share.open({
    //   message: `https://play.google.com/store/apps/details?id=com.vouch.payment.protection.marketplace`,
    // })
    //   .then
    //   // result =>    console.log(result)
    //   ()
    //   .catch
    //   // errorMsg => console.log(errorMsg)
    //   ();
  };

  const checkMilestonesAndUpdateStatus = (
    uid,
    mileid,
    status,
    details = "",
    imageData = ""
  ) => {
    var isLast = false;
    if (status == "completed") {
      var not_completed_milestones = props.gigMilestoneDetails.filter(
        x => x.milestone_status != "completed"
      );

      if (not_completed_milestones) {
        if (not_completed_milestones.length == 1) {
          if (not_completed_milestones[0].uid == mileid) {
            isLast = true;
          }
        }
      }
    }
    props.changeGigMilestoneStatus(
      uid,
      mileid,
      status,
      isLast,
      details,
      imageData
    );
  };

  const renderMilestones = () => {
    return props.gigMilestoneDetails.map((item, index) => {
      return (
        <GigMilestoneCard
          key={index}
          index={index + 1}
          gigDetails={props.gigDetails}
          {...item}
          loading={props.isLoading}
          fund={status => {
            if (item.payment_details.hasOwnProperty("paymentType")) {
              if (item.payment_details.paymentType == "smart_collect") {
                props.navigation.navigate("BankTransferDetails", {
                  data: {
                    account_id: item.payment_details.smart_collect_id,
                    gigData: props.gigDetails,
                    user_id: props.gigDetails.client_id,
                    mode: "Account_details",
                  },
                });
              } else {
                props.navigation.navigate("PaymentCheckout", {
                  data: {
                    transaction_details: {
                      payment_id: props.route.params.uid,
                      milestone_id: item.uid,
                    },
                    to_user: props.gigDetails.client_id,
                    amount: item.milestoneAmount,
                    type: "Fund_Milestone",
                  },
                });
              }
            } else {
              props.navigation.navigate("PaymentCheckout", {
                data: {
                  transaction_details: {
                    payment_id: props.route.params.uid,
                    milestone_id: item.uid,
                  },
                  to_user: props.gigDetails.client_id,
                  amount: item.milestoneAmount,
                  type: "Fund_Milestone",
                },
              });
            }
          }}
          redoMilestone={() => {
            setRedoDetails(item.details);
            setRedoMilestoneId(item.uid);
            props.overlayRedoToggle();
          }}
          showDeliveredDetails={() => {
            setTempData([item.delivery_details, item?.attachment_path]);
            setViewDeliveryDetailsModel(true);
          }}
          onActionCalled={status => {
            // checkMilestonesAndUpdateStatus(
            //   props.route.params.uid,
            //   item.uid,
            //   status,
            // )
            setTempUid(item.uid);
            setTempStatus(status);
            setAlertConfirmStart(true);
          }}
          onDeliveredCalled={status => {
            setTempUid(item.uid);
            setTempStatus(status);
            setDeliveryDetailsModel(true);
          }}
          onCompleteCalled={status => {
            setTempUid(item.uid);
            setAlertConfirmComplete(true);
          }}
        />
      );
    });
  };
  const ratingCompleted = rating => {
    // alert('Rating is: ' + rating)
    setRev1(rating);
  };
  const goToOverlayNoMilestone = () => {
    setRedo(props.gigMilestoneDetails[0].details);
    props.overlayRedoToggle();
  };
  const renderReviewsBlock = () => {
    return (
      <View>
        {/* <Dash
          style={{marginTop: 0, marginBottom: 20}}
          dashGap={3}
          dashLength={6}
          dashColor={'#707070'}
        /> */}
        <View
          style={{
            marginTop: 40,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SIcon name='check' style={{ fontSize: 40, color: "#09C98F" }} />
          <Text
            style={{
              fontFamily: "Roboto-Bold",
              fontSize: 16,
              color: config.primary_color,
              marginLeft: 10,
            }}
          >
            Payment Successfully completed
          </Text>
        </View>
        <View
          style={{ flexDirection: "row", marginBottom: "7%", marginTop: "7%" }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 16,
              color: config.primary_color,
              textAlign: "left",
              flex: 0.5,
            }}
          >
            Amount released
          </Text>
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 16,
              color: config.primary_color,
              textAlign: "right",
              flex: 0.5,
            }}
          >
            Rs {props.gigDetails.total_amount}
          </Text>
        </View>
        <View>
          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#707070",
              marginTop: 10,
              marginBottom: 15,
            }}
          >
            Please rate your{" "}
            {props.gigDetails.mode == "buying" ? "seller" : "buyer"} and give a
            review
          </Text>

          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#707070",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {props.gigDetails.mode == "buying" ? "Seller" : "Buyer"}{" "}
            communication
          </Text>
          <AirbnbRating
            count={5}
            reviews={["1", "2", "3", "4", "5"]}
            isDisabled={
              props.gigDetails?.review_present == false ? false : true
            }
            defaultRating={props.gigDetails.communication_review}
            showRating={false}
            size={30}
            starContainerStyle={{
              backgroundColor: "",
              alignSelf: "flex-start",
            }}
            onFinishRating={e => ratingCompleted(e)}
          />
          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#707070",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Service/Product as Described
          </Text>
          <AirbnbRating
            count={5}
            isDisabled={
              props.gigDetails?.review_present == false ? false : true
            }
            reviews={["1", "2", "3", "4", "5"]}
            defaultRating={props.gigDetails.order_review}
            showRating={false}
            size={30}
            starContainerStyle={{
              backgroundColor: "",
              alignSelf: "flex-start",
            }}
            onFinishRating={e => {
              // alert(e)
              setRev2(e);
            }}
          />

          {props.gigDetails?.review_present ? (
            <View>
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 16,
                  color: config.primary_color,
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Overall Rating
              </Text>
              <AirbnbRating
                count={5}
                isDisabled={true}
                reviews={["1", "2", "3", "4", "5"]}
                defaultRating={props.gigDetails.overall_review}
                showRating={false}
                size={40}
                starContainerStyle={{
                  backgroundColor: "",
                  alignSelf: "flex-start",
                }}
                onFinishRating={e => {}}
              />
            </View>
          ) : null}
        </View>

        {props.gigDetails.review_present == false ? (
          <View>
            {rev1 == 5 && rev2 == 5 ? (
              <View>
                <Text
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 16,
                    color: "#000",
                    textAlign: "center",
                    marginTop: 30,
                  }}
                >
                  Give Compliments
                </Text>
                {giveComplementClicked ? (
                  <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    style={{ marginTop: 15 }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setComplementSelected("excellent_communicator");
                        setComplementCodeSelected(0);
                      }}
                      style={{ width: 80, marginHorizontal: 10 }}
                    >
                      <ExcellentCommunicator
                        style={{
                          alignSelf: "center",
                          marginBottom: 5,
                          opacity: complementCodeSelected == 0 ? 1 : 0.5,
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: "Roboto-Medium",
                          fontSize: 12,
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        Excellent Communicator
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setComplementSelected("fast_delivery");
                        setComplementCodeSelected(1);
                      }}
                      style={{ width: 80, marginHorizontal: 10 }}
                    >
                      <FastDelivery
                        style={{
                          alignSelf: "center",
                          marginBottom: 5,
                          opacity: complementCodeSelected == 1 ? 1 : 0.5,
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: "Roboto-Medium",
                          fontSize: 12,
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        Fast{"\n"}Delivery
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setComplementSelected("six_star_service");
                        setComplementCodeSelected(2);
                      }}
                      style={{ width: 80, marginHorizontal: 10 }}
                    >
                      <SixStarService
                        style={{
                          alignSelf: "center",
                          marginBottom: 5,
                          opacity: complementCodeSelected == 2 ? 1 : 0.5,
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: "Roboto-Medium",
                          fontSize: 12,
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        6 Star{"\n"}service
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setComplementSelected("great_attitude");
                        setComplementCodeSelected(3);
                      }}
                      style={{ width: 80, marginHorizontal: 10 }}
                    >
                      <GreatAttitude
                        style={{
                          alignSelf: "center",
                          marginBottom: 5,
                          opacity: complementCodeSelected == 3 ? 1 : 0.5,
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: "Roboto-Medium",
                          fontSize: 12,
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        Great{"\n"}attitude
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setComplementSelected("miracle_worker");
                        setComplementCodeSelected(4);
                      }}
                      style={{ width: 80, marginHorizontal: 10 }}
                    >
                      <MiracleWorker
                        style={{
                          alignSelf: "center",
                          marginBottom: 5,
                          opacity: complementCodeSelected == 4 ? 1 : 0.5,
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: "Roboto-Medium",
                          fontSize: 12,
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        Miracle{"\n"}Worker
                      </Text>
                    </TouchableOpacity>
                  </ScrollView>
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      setGiveComplementClicked(true);
                    }}
                  >
                    <GetComplement
                      style={{
                        alignSelf: "center",
                        marginVertical: 15,
                        marginTop: 30,
                      }}
                    />
                  </TouchableOpacity>
                )}
              </View>
            ) : null}
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 16,
                color: "#464646",
                paddingLeft: 5,
                marginTop: 20,
              }}
            >
              Review
            </Text>
            <Textarea
              rowSpan={5}
              bordered
              name='requirements'
              placeholder='Enter your Review Here'
              borderRadius={10}
              placeholderTextColor={"#AAAAAA"}
              onChangeText={message => setReview(message)}
              value={review}
              style={{ fontSize: 14, padding: 15, marginBottom: 30 }}
            />
            <View
              style={{
                marginBottom: 30,
                borderTopWidth: 3,
                borderTopColor: "#AAA",
                borderStyle: "dashed",
                marginTop: 10,
                paddingTop: 40,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  var temp = document.getElementById("video-review");
                  temp.click();
                  temp.addEventListener("change", $event => {
                    var file = $event.target.files?.length
                      ? $event.target.files[0]
                      : null;
                    if (file) {
                      if (file.size > 20000000)
                        return alert("FIle size should be less than 20 MB");
                      imageService.toBase64(file).then(data => {
                        var imageData = {
                          uri: data,
                          mime: file.type,
                          name: file.name,
                          size: file.size,
                        };
                        setReviewAttachment(imageData);
                      });
                    }
                  });
                }}
                style={{
                  height: 73,
                  borderColor: "#ccc",
                  borderRadius: 7,
                  borderWidth: 1,
                  marginHorizontal: 15,
                }}
              >
                <input
                  type='file'
                  id='video-review'
                  hidden
                  accept='video/mp4,video/x-m4v,video/*'
                />
                {reviewAttachment.mime ? (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <View
                      style={{
                        width: 40,
                        height: 40,
                        padding: 5,
                        backgroundColor: "#ea4e42",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BIcon
                        name='video'
                        style={{ color: "#FFF", fontSize: 20 }}
                      />
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <AIcon
                      name='pluscircleo'
                      style={{
                        color: config.primary_color,
                        fontSize: 40,
                        textAlign: "center",
                        flex: 0.3,
                      }}
                    />
                    <Text
                      style={{
                        fontFamily: "Roboto-Regular",
                        fontSize: 14,
                        color: "#000",
                        flex: 0.6,
                      }}
                    >
                      Video Review
                    </Text>
                  </View>
                )}
              </TouchableOpacity>
              <View style={{ marginVertical: "10%", marginHorizontal: 15 }}>
                <View
                  style={{
                    alignSelf: "center",
                    position: "absolute",
                    borderBottomColor: "#707070",
                    borderBottomWidth: 1,
                    height: "55%",
                    width: "97%",
                  }}
                />
                <Text
                  style={{
                    backgroundColor: "#FFF",
                    zIndex: 1,
                    fontFamily: "Roboto-Bold",
                    fontSize: 18,
                    alignSelf: "center",
                    paddingHorizontal: 15,
                    color: config.primary_color,
                  }}
                >
                  OR
                </Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  var temp = document.getElementById("audio-review");
                  temp.click();
                  temp.addEventListener("change", $event => {
                    var file = $event.target.files?.length
                      ? $event.target.files[0]
                      : null;
                    if (file) {
                      if (file.size > 20000000)
                        return alert("FIle size should be less than 20 MB");
                      imageService.toBase64(file).then(data => {
                        var imageData = {
                          uri: data,
                          type: file.type,
                          name: file.name,
                          size: file.size,
                        };
                        setReviewAttachment(imageData);
                      });
                    }
                  });
                }}
                style={{
                  height: 73,
                  borderColor: "#ccc",
                  borderRadius: 7,
                  borderWidth: 1,
                  marginHorizontal: 15,
                }}
              >
                <input
                  type='file'
                  id='audio-review'
                  hidden
                  accept='.mp3,audio/*'
                />
                {reviewAttachment.type ? (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <View
                      style={{
                        width: 40,
                        height: 40,
                        padding: 5,
                        backgroundColor: "#ea4e42",
                        borderRadius: 5,
                      }}
                    >
                      <BIcon
                        name='headphones'
                        style={{ color: "#FFF", fontSize: 28 }}
                      />
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <AIcon
                      name='pluscircleo'
                      style={{
                        color: config.primary_color,
                        fontSize: 40,
                        textAlign: "center",
                        flex: 0.3,
                      }}
                    />
                    <Text
                      style={{
                        fontFamily: "Roboto-Regular",
                        fontSize: 14,
                        color: "#000",
                        flex: 0.6,
                      }}
                    >
                      Audio Review
                    </Text>
                  </View>
                )}
              </TouchableOpacity>
            </View>
            <PrimaryButton
              disabled={rev1 == null || rev2 == null || review.trim() == ""}
              content={"Submit"}
              clicked={() => {
                setTempStatus("Review");
                props.submitReview(
                  props.route.params.uid,
                  props.gigDetails.client_id,
                  props.gigDetails.mode,
                  rev1,
                  rev2,
                  complementSelected,
                  complementCodeSelected,
                  review,
                  reviewAttachment,
                  reviewAttachment.hasOwnProperty("mime")
                    ? "video"
                    : reviewAttachment.hasOwnProperty("type")
                    ? "audio"
                    : null
                );
                setReviewSubmitted(false);
              }}
            />
          </View>
        ) : (
          <View>
            {props.gigDetails.complement_code ? (
              <View>
                <Text
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 16,
                    color: "#464646",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  Compliment
                </Text>
                {props.gigDetails.complement_code == 0 ? (
                  <View>
                    <ExcellentCommunicator
                      style={{ marginBottom: 5, opacity: 1 }}
                    />
                    <Text
                      style={{
                        fontFamily: "Roboto-Medium",
                        fontSize: 12,
                        color: "#000",
                      }}
                    >
                      Excellent Communicator
                    </Text>
                  </View>
                ) : null}
                {props.gigDetails.complement_code == 1 ? (
                  <View>
                    <FastDelivery style={{ marginBottom: 5, opacity: 1 }} />
                    <Text
                      style={{
                        fontFamily: "Roboto-Medium",
                        fontSize: 12,
                        color: "#000",
                      }}
                    >
                      Fast Delivery
                    </Text>
                  </View>
                ) : null}
                {props.gigDetails.complement_code == 2 ? (
                  <View>
                    <SixStarService style={{ marginBottom: 5, opacity: 1 }} />
                    <Text
                      style={{
                        fontFamily: "Roboto-Medium",
                        fontSize: 12,
                        color: "#000",
                      }}
                    >
                      6 Star Service
                    </Text>
                  </View>
                ) : null}
                {props.gigDetails.complement_code == 3 ? (
                  <View>
                    <GreatAttitude style={{ marginBottom: 5, opacity: 1 }} />
                    <Text
                      style={{
                        fontFamily: "Roboto-Medium",
                        fontSize: 12,
                        color: "#000",
                      }}
                    >
                      Great Attitude
                    </Text>
                  </View>
                ) : null}
                {props.gigDetails.complement_code == 4 ? (
                  <View>
                    <MiracleWorker style={{ marginBottom: 5, opacity: 1 }} />
                    <Text
                      style={{
                        fontFamily: "Roboto-Medium",
                        fontSize: 12,
                        color: "#000",
                      }}
                    >
                      Miracle Worker
                    </Text>
                  </View>
                ) : null}
              </View>
            ) : null}
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginTop: 30,
                textAlign: "center",
              }}
            >
              Do you love using vouch.{"\n"}
              Recommend vouch to your{"\n"}
              friends and family{"\n"}
            </Text>
            <PrimaryButton content={"Share"} clicked={() => shareMessage()} />
          </View>
        )}
      </View>
    );
  };
  const renderHelpText = () => {
    return (
      <TouchableOpacity onPress={() => props.navigation.navigate("WhatsApp")}>
        <Text style={styles.helpMainText}>
          Have any issues with your order?
        </Text>
        <Text style={styles.helpSubText}>Talk to Us. We are here to Help</Text>
      </TouchableOpacity>
    );
  };

  const renderScene = ({ route }) => {
    switch (route.key) {
      case "Details":
        return (
          <ScrollView showsVerticalScrollIndicator={false} ref={scrollindDown}>
            <View style={{ marginHorizontal: "8%", marginTop: "5%" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {props?.profile?.userProfile?.profile_image_path ? (
                  <Thumbnail
                    source={{
                      uri: props?.profile?.userProfile?.profile_image_path,
                    }}
                    style={{
                      width: 60,
                      height: 60,
                    }}
                  />
                ) : (
                  <View
                    style={{
                      width: 60,
                      height: 60,
                      backgroundColor: "#F2F2F2",
                      borderRadius: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#AAAAAA",
                        textAlign: "center",
                        fontFamily: "Roboto-Regular",
                        textTransform: "capitalize",
                      }}
                    >
                      {props?.profile?.userProfile?.company_name
                        ? props?.profile?.userProfile?.company_name
                          ? props?.profile?.userProfile?.company_name[0]
                          : " "
                        : props?.profile?.userProfile?.firstname
                        ? props?.profile?.userProfile?.firstname[0]
                        : " "}
                    </Text>
                  </View>
                )}

                <View
                  style={{ flex: 0.35, height: 10, backgroundColor: "#F2F2F2" }}
                ></View>
                <View
                  style={{
                    width: 100,
                    height: 100,
                    backgroundColor:
                      props.gigDetails.order_status == "rejected" ||
                      props.gigDetails.order_status == "cancelled"
                        ? "#D92E0B"
                        : config.primary_color,
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {props.gigDetails.order_status == "rejected" ||
                  props.gigDetails.order_status == "cancelled" ? (
                    <AIcon
                      name='exclamation'
                      style={{ color: "#FFF", fontSize: 60 }}
                    />
                  ) : props.gigDetails.orderCompletelySettled ? (
                    <FIcon
                      name='check'
                      style={{ color: "#FFF", fontSize: 60 }}
                    />
                  ) : (
                    <Text
                      style={{
                        fontFamily: "OpenSans-Bold",
                        fontSize: 14,
                        color: "#FFF",
                      }}
                    >
                      {`\u20B9 ${props.gigDetails.total_amount}`}
                    </Text>
                  )}
                </View>
                <View
                  style={{ flex: 0.35, height: 10, backgroundColor: "#F2F2F2" }}
                ></View>
                {props?.clickedUser?.profile_image_path ? (
                  <Thumbnail
                    source={{
                      uri: props?.clickedUser?.profile_image_path,
                    }}
                    style={{
                      width: 60,
                      height: 60,
                    }}
                  />
                ) : (
                  <View
                    style={{
                      width: 60,
                      height: 60,
                      backgroundColor: "#F2F2F2",
                      borderRadius: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#AAAAAA",
                        textAlign: "center",
                        fontFamily: "Roboto-Regular",
                        textTransform: "capitalize",
                      }}
                    >
                      {props?.clickedUser?.company_name
                        ? props?.clickedUser?.company_name
                          ? props?.clickedUser?.company_name[0]
                          : " "
                        : props?.clickedUser.firstname
                        ? props?.clickedUser.firstname[0]
                        : " "}
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "OpenSans-SemiBold",
                    fontSize: 12,
                    color: config.primary_color,
                    width: 60,
                    textAlign: "center",
                    marginTop: -40,
                  }}
                >
                  You
                </Text>
                <View style={{ flex: 0.35, height: 10 }}></View>
                <Text
                  style={{
                    fontFamily: "OpenSans-SemiBold",
                    fontSize: 16,
                    color: config.primary_color,
                    width: 100,
                    textAlign: "center",
                  }}
                >
                  Vouch
                </Text>
                <View style={{ flex: 0.35, height: 10 }}></View>
                <Text
                  style={{
                    fontFamily: "OpenSans-SemiBold",
                    fontSize: 12,
                    color: config.primary_color,
                    width: 60,
                    textAlign: "center",
                    marginTop: -40,
                  }}
                  numberOfLines={1}
                >
                  {props?.clickedUser?.company_name
                    ? props?.clickedUser?.company_name
                    : props?.clickedUser?.firstname}
                </Text>
              </View>

              <Text
                style={{
                  fontFamily: "OpenSans-Semibold",
                  lineHeight: 22,
                  fontSize: 14,
                  color: "#464646",
                  textAlign: "center",
                  marginTop: 40,
                }}
              >
                {props.gigDetails.order_status == "rejected"
                  ? "Gig Rejected"
                  : props.gigDetails.order_status == "cancelled"
                  ? "Gig Cancelled"
                  : props.gigDetails.orderCompletelySettled
                  ? "Gig Completed"
                  : `\u20B9 ${props.gigDetails.total_amount} held by vouch. Will be released upon delivery approval`}
              </Text>

              <View
                style={{
                  borderColor: "#DCDCDC",
                  borderWidth: 1,
                  marginTop: 30,
                  paddingHorizontal: 25,
                  paddingVertical: 10,
                  borderRadius: 25,
                  marginBottom: 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    fontFamily: "Roboto-Regular",
                    color: "#464646",
                  }}
                >
                  {props.gigDetails.requirements}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "Roboto-Regular",
                    color: "#828282",
                    marginTop: 15,
                  }}
                >
                  Payment Id : {props.route.params.uid}
                  {"\n"}
                  Created on {props.gigDetails.formattedDate}
                </Text>
              </View>

              {props.gigDetails.hasOwnProperty("classification") ? (
                <View
                  style={{
                    borderRadius: 15,
                    backgroundColor: "#fff",
                    elevation: 2,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.1,
                    shadowRadius: 3.84,
                    marginTop: 40,
                    marginBottom: 40,
                  }}
                >
                  {props.gigDetails?.timeLinePreview?.color == "#E89C04" &&
                  props.gigDetails?.timeLinePreview?.actionBy ==
                    props.gigDetails.mode ? (
                    <View
                      style={{
                        borderRadius: 15,
                        paddingVertical: 15,
                        flexDirection: "row",
                        backgroundColor: "#fff",
                        elevation: 2,
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.1,
                        shadowRadius: 3.84,
                      }}
                    >
                      <View style={{ flex: 0.17, alignItems: "center" }}>
                        {props.gigDetails.order_status == "rejected" ||
                        props.gigDetails.order_status == "cancelled" ? (
                          <BIcon
                            name='exclamation-triangle'
                            style={{
                              color: "#F90000",
                              marginRight: 10,
                              fontSize: 18,
                              marginLeft: 10,
                            }}
                          />
                        ) : null}
                        {props.gigDetails.orderCompletelySettled ? (
                          <AIcon
                            name='checkcircleo'
                            style={{
                              color: "#0BA888",
                              marginRight: 10,
                              fontSize: 25,
                              marginLeft: 10,
                            }}
                          />
                        ) : props.gigDetails.order_status == "yet_to_confirm" ||
                          props.gigDetails.order_status == "yet_to_pay" ||
                          props.gigDetails.order_status == "completed" ||
                          props.gigDetails.order_status == "confirmed" ? (
                          <FIcon
                            name='clock'
                            style={{
                              color: props.gigDetails?.timeLinePreview?.color,
                              paddingRight: 10,
                              fontSize: 25,
                              marginLeft: 10,
                            }}
                          />
                        ) : null}
                      </View>
                      <View style={{ flex: 0.7 }}>
                        <Text
                          numberOfLines={1}
                          style={{
                            fontFamily: "OpenSans-SemiBold",
                            marginRight: 20,
                            color: props.gigDetails?.timeLinePreview?.color,
                            fontSize: 14,
                          }}
                        >
                          {props.gigDetails?.timeLinePreview?.message} lets
                          eloberate this message
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 12,
                            alignItems: "center",
                          }}
                        >
                          {props.gigDetails.order_status == "rejected" ||
                          props.gigDetails.order_status == "cancelled" ? (
                            <Text
                              style={{
                                fontFamily: "OpenSans-Regular",
                                color: "#464646",
                                fontSize: 10,
                              }}
                            >
                              Rejected
                            </Text>
                          ) : null}
                          {props.gigDetails.orderCompletelySettled ? (
                            <Text
                              style={{
                                fontFamily: "OpenSans-Regular",
                                color: "#464646",
                                fontSize: 10,
                              }}
                            >
                              Completed
                            </Text>
                          ) : (
                            <Text
                              style={{
                                fontFamily: "OpenSans-Regular",
                                color: "#464646",
                                fontSize: 10,
                              }}
                            >
                              Current status
                            </Text>
                          )}
                          {props.gigDetails?.timeLinePreview?.color ==
                            "#E89C04" &&
                          props.gigDetails?.timeLinePreview?.actionBy ==
                            props.gigDetails.mode ? (
                            <TouchableOpacity
                              onPress={() => {
                                var temp = props.timeline.find(item =>
                                  item.hasOwnProperty("sellerAccountAdded")
                                );
                                if (
                                  temp?.color == "#E89C04" &&
                                  temp?.actionBy == props.gigDetails.mode
                                ) {
                                  props.navigation.navigate("MyBank");
                                } else {
                                  scrollindDown.current.scrollToEnd({
                                    animated: true,
                                  });
                                  props.setActionPending();
                                }
                              }}
                              style={{
                                marginLeft: 30,
                                backgroundColor: "#D92E0B",
                                borderRadius: 15,
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "OpenSans-Regular",
                                  padding: 5,
                                  color: "#FFF",
                                  fontSize: 8,
                                }}
                              >
                                Action Pending
                              </Text>
                            </TouchableOpacity>
                          ) : null}
                        </View>
                      </View>
                      <TouchableOpacity
                        onPress={() => props.scrollToggle()}
                        style={{
                          flex: 0.13,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {props.scrollDown ? (
                          <AIcon
                            name='up'
                            style={{ paddingRight: 8, fontSize: 15 }}
                          />
                        ) : (
                          <AIcon
                            name='down'
                            style={{ paddingRight: 8, fontSize: 15 }}
                          />
                        )}
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <TouchableOpacity
                      onPress={() => props.scrollToggle()}
                      style={{
                        borderRadius: 15,
                        paddingVertical: 15,
                        flexDirection: "row",
                        backgroundColor: "#fff",
                        elevation: 2,
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.1,
                        shadowRadius: 3.84,
                      }}
                    >
                      <View style={{ flex: 0.17, alignItems: "center" }}>
                        {props.gigDetails.order_status == "rejected" ||
                        props.gigDetails.order_status == "cancelled" ? (
                          <BIcon
                            name='exclamation-triangle'
                            style={{
                              color: "#F90000",
                              marginRight: 10,
                              fontSize: 18,
                              marginLeft: 10,
                            }}
                          />
                        ) : null}
                        {props.gigDetails.orderCompletelySettled ? (
                          <AIcon
                            name='checkcircleo'
                            style={{
                              color: "#0BA888",
                              marginRight: 10,
                              fontSize: 25,
                              marginLeft: 10,
                            }}
                          />
                        ) : props.gigDetails.order_status == "yet_to_confirm" ||
                          props.gigDetails.order_status == "completed" ||
                          props.gigDetails.order_status == "confirmed" ? (
                          <FIcon
                            name='clock'
                            style={{
                              color: props.gigDetails?.timeLinePreview?.color,
                              paddingRight: 10,
                              fontSize: 25,
                              marginLeft: 10,
                            }}
                          />
                        ) : null}
                      </View>
                      <View style={{ flex: 0.73 }}>
                        <Text
                          numberOfLines={1}
                          style={{
                            fontFamily: "OpenSans-SemiBold",
                            marginRight: 20,
                            color: props.gigDetails?.timeLinePreview?.color,
                            fontSize: 14,
                          }}
                        >
                          {props.gigDetails?.timeLinePreview?.message}
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 12,
                            alignItems: "center",
                          }}
                        >
                          {props.gigDetails.order_status == "rejected" ||
                          props.gigDetails.order_status == "cancelled" ? (
                            <Text
                              style={{
                                fontFamily: "OpenSans-Regular",
                                color: "#464646",
                                fontSize: 10,
                              }}
                            >
                              Rejected
                            </Text>
                          ) : null}
                          {props.gigDetails.orderCompletelySettled ? (
                            <Text
                              style={{
                                fontFamily: "OpenSans-Regular",
                                color: "#464646",
                                fontSize: 10,
                              }}
                            >
                              Completed
                            </Text>
                          ) : (
                            <Text
                              style={{
                                fontFamily: "OpenSans-Regular",
                                color: "#464646",
                                fontSize: 10,
                              }}
                            >
                              Current status
                            </Text>
                          )}
                        </View>
                      </View>
                      <View
                        style={{
                          flex: 0.1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {props.scrollDown ? (
                          <AIcon
                            name='up'
                            style={{ paddingRight: 8, fontSize: 15 }}
                          />
                        ) : (
                          <AIcon
                            name='down'
                            style={{ paddingRight: 8, fontSize: 15 }}
                          />
                        )}
                      </View>
                    </TouchableOpacity>
                  )}
                  {props.scrollDown ? (
                    <View style={{ paddingTop: 30, paddingRight: 10 }}>
                      <TimelineComponent
                        routeToMyBank={() => {
                          props.navigation.navigate("MyBank");
                        }}
                        scrollBottom={() => {
                          scrollindDown.current.scrollToEnd({ animated: true });
                          props.setActionPending();
                        }}
                        data={props.timeline}
                        mode={props.gigDetails.mode}
                      />
                    </View>
                  ) : null}
                </View>
              ) : null}

              {props.gigDetails.order_status == "completed"
                ? renderReviewsBlock()
                : null}

              {props.gigDetails.order_status != "completed" ? (
                <View style={{ flexDirection: "row", marginBottom: "7%" }}>
                  <Text
                    style={{
                      fontFamily: "Roboto-Medium",
                      fontSize: 16,
                      color: "#464646",
                      textAlign: "left",
                      flex: 0.5,
                    }}
                  >
                    Amount
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Roboto-Medium",
                      fontSize: 16,
                      color: "#464646",
                      textAlign: "right",
                      flex: 0.5,
                    }}
                  >
                    {`\u20B9${props.gigDetails.total_amount}`}
                  </Text>
                </View>
              ) : null}

              {props.gigDetails.current_user_initiated ? (
                <View>
                  {props.gigDetails.mode == "buying" &&
                  (props.gigDetails.total_amount_paid == true ||
                    props.gigMilestoneDetails[0].paid == true) &&
                  props.gigDetails.order_status == "yet_to_confirm" ? (
                    <View style={{ marginBottom: 20 }}>
                      <PrimaryButton
                        content={"Cancel Gig"}
                        loading={props.isLoading}
                        clicked={() => {
                          setTempStatus("Refund");
                          props.refundOrder(props.route.params.uid, "buyer");
                        }}
                      />
                    </View>
                  ) : null}
                  {props.gigDetails.mode == "buying" &&
                  props.gigDetails.total_amount_paid == false &&
                  props.gigMilestoneDetails[0].paid == false &&
                  (props.gigDetails.order_status == "yet_to_confirm" ||
                    props.gigDetails.order_status == "yet_to_pay") ? (
                    <View>
                      <PrimaryButton
                        content={"Fund full Amount"}
                        loading={props.isLoading}
                        clicked={() => {
                          if (
                            props.gigMilestoneDetails[0].payment_details.hasOwnProperty(
                              "paymentType"
                            )
                          ) {
                            if (
                              props.gigMilestoneDetails[0].payment_details
                                .paymentType == "smart_collect"
                            ) {
                              props.navigation.navigate("BankTransferDetails", {
                                data: {
                                  account_id:
                                    props.gigMilestoneDetails[0].payment_details
                                      .smart_collect_id,
                                  gigData: props.gigDetails,
                                  user_id: props.gigDetails.client_id,
                                  mode: "Account_details",
                                },
                              });
                            } else {
                              props.navigation.navigate("PaymentCheckout", {
                                data: {
                                  transaction_details: {
                                    payment_id: props.route.params.uid,
                                    milestone_id:
                                      props.gigMilestoneDetails[0].uid,
                                  },
                                  to_user: props.gigDetails.client_id,
                                  amount: props.gigDetails.total_amount,
                                  type: "Fund_Full_Payment",
                                },
                              });
                            }
                          } else {
                            props.navigation.navigate("PaymentCheckout", {
                              data: {
                                transaction_details: {
                                  payment_id: props.route.params.uid,
                                  milestone_id:
                                    props.gigMilestoneDetails[0].uid,
                                },
                                to_user: props.gigDetails.client_id,
                                amount: props.gigDetails.total_amount,
                                type: "Fund_Full_Payment",
                              },
                            });
                          }
                        }}
                      />
                      {props.gigDetails.isVirtual == false ? (
                        <View>
                          <Text
                            style={{
                              fontFamily: "OpenSans-Bold",
                              fontSize: 20,
                              color: config.primary_color,
                              textAlign: "center",
                              marginTop: 30,
                            }}
                          >
                            Or
                          </Text>
                          <PrimaryButton
                            content={"Fund 1st Milestone"}
                            loading={props.isLoading}
                            clicked={() => {
                              if (
                                props.gigMilestoneDetails[0].payment_details.hasOwnProperty(
                                  "paymentType"
                                )
                              ) {
                                if (
                                  props.gigMilestoneDetails[0].payment_details
                                    .paymentType == "smart_collect"
                                ) {
                                  props.navigation.navigate(
                                    "BankTransferDetails",
                                    {
                                      data: {
                                        account_id:
                                          props.gigMilestoneDetails[0]
                                            .payment_details.smart_collect_id,
                                        gigData: props.gigDetails,
                                        user_id: props.gigDetails.client_id,
                                        mode: "Account_details",
                                      },
                                    }
                                  );
                                } else {
                                  props.navigation.navigate("PaymentCheckout", {
                                    data: {
                                      transaction_details: {
                                        payment_id: props.route.params.uid,
                                        milestone_id:
                                          props.gigMilestoneDetails[0].uid,
                                      },
                                      to_user: props.gigDetails.client_id,
                                      amount:
                                        props.gigMilestoneDetails[0]
                                          .milestoneAmount,
                                      type: "Fund_Milestone",
                                    },
                                  });
                                }
                              } else {
                                props.navigation.navigate("PaymentCheckout", {
                                  data: {
                                    transaction_details: {
                                      payment_id: props.route.params.uid,
                                      milestone_id:
                                        props.gigMilestoneDetails[0].uid,
                                    },
                                    to_user: props.gigDetails.client_id,
                                    amount:
                                      props.gigMilestoneDetails[0]
                                        .milestoneAmount,
                                    type: "Fund_Milestone",
                                  },
                                });
                              }
                            }}
                          />
                          <View style={{ height: 30 }}></View>
                        </View>
                      ) : null}
                    </View>
                  ) : null}
                </View>
              ) : (
                <View>
                  {props.gigDetails.mode == "selling" &&
                  (props.gigDetails.total_amount_paid == true ||
                    props.gigMilestoneDetails[0].paid == true) &&
                  props.gigDetails.order_status == "yet_to_confirm" ? (
                    <View style={{ paddingBottom: 20 }}>
                      <PrimaryButton
                        content={"Accept Order"}
                        loading={props.isLoading}
                        clicked={() => {
                          props.changeGigOrderStatus(
                            props.route.params.uid,
                            "confirmed"
                          );
                        }}
                      />
                      <RejectedButton
                        content={"Reject Order"}
                        loading={props.isLoading}
                        clicked={() => {
                          setTempStatus("rejected");
                          props.changeGigOrderStatus(
                            props.route.params.uid,
                            "rejected"
                          );
                        }}
                      />
                    </View>
                  ) : null}
                  {props.gigDetails.mode == "buying" &&
                  props.gigDetails.order_status == "yet_to_confirm" ? (
                    <View>
                      <PrimaryButton
                        content={"Fund full Amount"}
                        loading={props.isLoading}
                        clicked={() => {
                          if (
                            props.gigMilestoneDetails[0].payment_details.hasOwnProperty(
                              "paymentType"
                            )
                          ) {
                            if (
                              props.gigMilestoneDetails[0].payment_details
                                .paymentType == "smart_collect"
                            ) {
                              props.navigation.navigate("BankTransferDetails", {
                                data: {
                                  account_id:
                                    props.gigMilestoneDetails[0].payment_details
                                      .smart_collect_id,
                                  gigData: props.gigDetails,
                                  user_id: props.gigDetails.client_id,
                                  mode: "Account_details",
                                },
                              });
                            } else {
                              props.navigation.navigate("PaymentCheckout", {
                                data: {
                                  transaction_details: {
                                    payment_id: props.route.params.uid,
                                    milestone_id:
                                      props.gigMilestoneDetails[0].uid,
                                  },
                                  to_user: props.gigDetails.client_id,
                                  amount: props.gigDetails.total_amount,
                                  type: "Fund_Full_Payment",
                                },
                              });
                            }
                          } else {
                            props.navigation.navigate("PaymentCheckout", {
                              data: {
                                transaction_details: {
                                  payment_id: props.route.params.uid,
                                  milestone_id:
                                    props.gigMilestoneDetails[0].uid,
                                },
                                to_user: props.gigDetails.client_id,
                                amount: props.gigDetails.total_amount,
                                type: "Fund_Full_Payment",
                              },
                            });
                          }
                        }}
                      />
                      {props.gigDetails.isVirtual == false ? (
                        <View>
                          <Text
                            style={{
                              fontFamily: "OpenSans-Bold",
                              fontSize: 20,
                              color: config.primary_color,
                              textAlign: "center",
                              marginTop: 30,
                            }}
                          >
                            Or
                          </Text>
                          <PrimaryButton
                            content={"Fund 1st Milestone"}
                            loading={props.isLoading}
                            clicked={() => {
                              if (
                                props.gigMilestoneDetails[0].payment_details.hasOwnProperty(
                                  "paymentType"
                                )
                              ) {
                                if (
                                  props.gigMilestoneDetails[0].payment_details
                                    .paymentType == "smart_collect"
                                ) {
                                  props.navigation.navigate(
                                    "BankTransferDetails",
                                    {
                                      data: {
                                        account_id:
                                          props.gigMilestoneDetails[0]
                                            .payment_details.smart_collect_id,
                                        gigData: props.gigDetails,
                                        user_id: props.gigDetails.client_id,
                                        mode: "Account_details",
                                      },
                                    }
                                  );
                                } else {
                                  props.navigation.navigate("PaymentCheckout", {
                                    data: {
                                      transaction_details: {
                                        payment_id: props.route.params.uid,
                                        milestone_id:
                                          props.gigMilestoneDetails[0].uid,
                                      },
                                      to_user: props.gigDetails.client_id,
                                      amount:
                                        props.gigMilestoneDetails[0]
                                          .milestoneAmount,
                                      type: "Fund_Milestone",
                                    },
                                  });
                                }
                              } else {
                                props.navigation.navigate("PaymentCheckout", {
                                  data: {
                                    transaction_details: {
                                      payment_id: props.route.params.uid,
                                      milestone_id:
                                        props.gigMilestoneDetails[0].uid,
                                    },
                                    to_user: props.gigDetails.client_id,
                                    amount:
                                      props.gigMilestoneDetails[0]
                                        .milestoneAmount,
                                    type: "Fund_Milestone",
                                  },
                                });
                              }
                            }}
                          />
                          <View style={{ height: 30 }}></View>
                        </View>
                      ) : null}
                    </View>
                  ) : null}
                </View>
              )}

              {props.gigDetails.isVirtual ? (
                <View>
                  {props.gigDetails.mode == "selling" &&
                  props.gigDetails.order_status == "confirmed" &&
                  props.gigMilestoneDetails[0].milestone_status ==
                    "yet_to_start" &&
                  (props.gigMilestoneDetails[0].paid ||
                    props.gigDetails.total_amount_paid) ? (
                    <View style={styles.buttonsContainer}>
                      <PrimaryButton
                        content={"Start"}
                        loading={props.isLoading}
                        styles={styles.cardButton}
                        clicked={() => {
                          setTempUid(props.gigMilestoneDetails[0].uid);
                          setTempStatus("started");
                          setAlertConfirmStart(true);
                        }}
                      />
                    </View>
                  ) : null}

                  {props.gigDetails.mode == "selling" &&
                  props.gigDetails.order_status == "confirmed" &&
                  (props.gigMilestoneDetails[0].milestone_status == "started" ||
                    props.gigMilestoneDetails[0].milestone_status ==
                      "requested_modification") ? (
                    <View>
                      <Text
                        style={{
                          fontFamily: "Roboto-Regular",
                          fontSize: 14,
                          color: "#707070",
                          marginVertical: 4,
                          width: "80%",
                        }}
                      >
                        Modifications :
                        {props.gigMilestoneDetails[0].modifications}
                      </Text>
                      <View
                        style={{
                          marginVertical: 10,
                          flexDirection: "row",
                          flexWrap: "wrap",
                          flex: 1,
                          marginBottom: 50,
                        }}
                      >
                        <PrimaryButton
                          content={"Mark as Complete"}
                          styles={styles.cardButton}
                          loading={props.isLoading}
                          clicked={() => {
                            setTempUid(props.gigMilestoneDetails[0].uid);
                            setTempStatus("delivered");
                            setDeliveryDetailsModel(true);
                          }}
                        />
                      </View>
                    </View>
                  ) : null}

                  {props.gigDetails.mode == "buying" &&
                  props.gigDetails.order_status == "confirmed" &&
                  props.gigMilestoneDetails[0].milestone_status ==
                    "delivered" ? (
                    <View>
                      <View style={styles.buttonsContainer}>
                        <PrimaryButton
                          content={
                            props.gigDetails?.classification == "product"
                              ? "Replace"
                              : "Redo"
                          }
                          styles={styles.cardButton}
                          loading={props.isLoading}
                          clicked={() => {
                            setRedoDetails(
                              props.gigMilestoneDetails[0].details
                            );
                            setRedoMilestoneId(
                              props.gigMilestoneDetails[0].uid
                            );
                            props.overlayRedoToggle();
                          }}
                        />
                        <PrimaryButton
                          content={"Accept"}
                          styles={styles.cardButton}
                          loading={props.isLoading}
                          clicked={() => {
                            setTempUid(props.gigMilestoneDetails[0].uid);
                            setAlertConfirmCompleteVirtual(true);
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          color: "#D92E0B",
                          marginBottom: 30,
                          fontFamily: "Roboto-Regular",
                          fontSize: 12,
                          lineHeight: 21,
                        }}
                      >
                        You have 3 days to request a change or accept the order,
                        after 3 days your order will be marked as accepted
                        automatically
                      </Text>
                    </View>
                  ) : null}
                </View>
              ) : null}
              {props.gigDetails.isVirtual ? (
                <View>
                  {props.gigMilestoneDetails[0].milestone_status !=
                  "completed" ? (
                    <Text
                      style={{
                        fontFamily: "Roboto-Regular",
                        fontSize: 14,
                        color: config.primary_color,
                        marginTop: 20,
                        width: "80%",
                        textTransform: "capitalize",
                      }}
                    >
                      {props.gigMilestoneDetails[0].milestone_status ==
                      "yet_to_start"
                        ? "Yet To Start Work"
                        : props.gigMilestoneDetails[0].milestone_status ==
                          "started"
                        ? "Milestone Started"
                        : props.gigMilestoneDetails[0].milestone_status ==
                          "delivered"
                        ? "Milestone Deliverd"
                        : props.gigMilestoneDetails[0].milestone_status ==
                          "requested_modification"
                        ? "Modification Requested"
                        : props.gigMilestoneDetails[0].milestone_status ==
                          "cancelled"
                        ? "Cancelled"
                        : props.gigMilestoneDetails[0].milestone_status ==
                          "rejected"
                        ? "Rejected"
                        : null}
                    </Text>
                  ) : null}
                  {props.gigDetails.mode == "buying" &&
                  props.gigDetails.order_status == "confirmed" &&
                  props.gigMilestoneDetails[0].milestone_status ==
                    "delivered" ? (
                    <View style={styles.buttonsContainer}>
                      <PrimaryButton
                        content={"View Delivery Details"}
                        styles={styles.cardButton}
                        clicked={() => {
                          setTempData([
                            props.gigMilestoneDetails[0]?.delivery_details,
                            props.gigMilestoneDetails[0]?.attachment_path,
                          ]);
                          setViewDeliveryDetailsModel(true);
                        }}
                      />
                    </View>
                  ) : null}
                </View>
              ) : (
                <View>
                  <Text
                    style={{
                      fontFamily: "Roboto-Bold",
                      fontSize: 18,
                      color: config.primary_color,
                    }}
                  >
                    Milestone
                  </Text>
                  {renderMilestones()}
                </View>
              )}
              {renderHelpText()}
            </View>
          </ScrollView>
        );
      case "Chat":
        return (
          <TopicChat
            parent_data={{
              thread_id: props.route.params.uid,
            }}
          />
        );
      default:
        return null;
    }
  };
  const renderTabBar = props => (
    <TabBar
      {...props}
      renderLabel={({ route, focused, color }) => (
        <Text
          style={{ fontSize: 20, color, margin: 8, fontFamily: "Roboto-Bold" }}
        >
          {route.title}
        </Text>
      )}
      indicatorStyle={{ backgroundColor: "white" }}
      style={{
        backgroundColor: "#FFF",
        shadowOffset: { height: 0, width: 0 },
        shadowColor: "transparent",
        shadowOpacity: 0,
        elevation: 0,
      }}
      activeColor={config.primary_color}
      inactiveColor={"#000"}
    />
  );

  const _renderLazyPlaceholder = ({ route }) => (
    <LazyPlaceholder route={route} />
  );

  const LazyPlaceholder = ({ route }) => (
    <View style={styles.scene}>
      <Text>Loading {route.title}…</Text>
    </View>
  );

  const me = () => {
    var p = props.gigMilestoneDetails.find(a => {
      if (a.uid == tempUid) {
        return a.milestoneAmount;
      }
    });
    return p ? p.milestoneAmount : null;
  };

  return props.isLoading ? (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFF",
      }}
    >
      <ActivityIndicator size='large' color={config.primary_color} />
    </View>
  ) : (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <View style={{ flexDirection: "row", padding: 20, paddingBottom: 20 }}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => props.navigation.goBack()}
        >
          <AIcon name='left' style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 22,
            fontFamily: "OpenSans-Bold",
            color: config.primary_color,
            // marginTop:3,
            marginLeft: 10,
          }}
        >
          {props.gigDetails.gig_name}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <TabView
          lazy={true}
          renderTabBar={renderTabBar}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          // initialLayout={initialLayout}
          renderLazyPlaceholder={_renderLazyPlaceholder}
          style={{
            shadowOffset: { height: 0, width: 0 },
            shadowColor: "transparent",
            shadowOpacity: 0,
            elevation: 0,
          }}
        />
      </View>

      <AwesomeAlert
        show={alertConformStart}
        title=''
        message=''
        messageStyle={{ textAlign: "center" }}
        customView={
          <View
            style={{
              alignItems: "center",
              paddingVertical: 10,
              lineHeight: 21,
              paddingHorizontal: 10,
            }}
          >
            {tempStatus == "started" ? (
              <View>
                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    fontSize: 16,
                    color: "#707070",
                    lineHeight: 21,
                  }}
                >
                  We know something awesome is brewing
                </Text>
                {/* <Image source={require('../../assets/images/pot.png')} style={{alignSelf: 'center',width:'35%',height:'80%'}}/>
                 */}
                <Pot
                  style={{
                    alignSelf: "center",
                    marginBottom: 20,
                    marginTop: 10,
                  }}
                />
              </View>
            ) : null}
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 16,
                color: "#707070",
                lineHeight: 21,
              }}
            >
              {`Are you sure you want to ${
                tempStatus == "started"
                  ? "start the order"
                  : "mark the order as completed"
              }? ${
                props?.clickedUser?.company_name
                  ? props?.clickedUser?.company_name
                  : props?.clickedUser?.firstname
              } will be notified`}
            </Text>
          </View>
        }
        showCancelButton={true}
        showConfirmButton={true}
        confirmText='Yes'
        cancelText='No'
        confirmButtonColor={config.primary_color}
        onCancelPressed={() => {
          setAlertConfirmStart(false);
        }}
        onConfirmPressed={() => {
          setAlertConfirmStart(false);
          checkMilestonesAndUpdateStatus(
            props.route.params.uid,
            tempUid,
            tempStatus
          );
        }}
      />

      <AwesomeAlert
        show={alertConformComplete}
        title=''
        message=''
        messageStyle={{ textAlign: "center" }}
        customView={
          <View
            style={{
              alignItems: "center",
              paddingVertical: 10,
              lineHeight: 21,
              paddingHorizontal: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 16,
                color: "#707070",
                lineHeight: 21,
              }}
            >
              Are you sure you want to mark the milestone as complete?
              {" " + props?.clickedUser?.company_name
                ? props?.clickedUser?.company_name
                : props?.clickedUser?.firstname}{" "}
              will be notified and {"\u20B9 " + me()} will be released to{" "}
              {props?.clickedUser?.company_name
                ? props?.clickedUser?.company_name
                : props?.clickedUser?.firstname}
            </Text>
          </View>
        }
        showCancelButton={true}
        showConfirmButton={true}
        confirmText='Yes'
        cancelText='No'
        confirmButtonColor={config.primary_color}
        onCancelPressed={() => {
          setAlertConfirmComplete(false);
        }}
        onConfirmPressed={() => {
          setAlertConfirmComplete(false);
          checkMilestonesAndUpdateStatus(
            props.route.params.uid,
            tempUid,
            "completed"
          );
        }}
      />

      <AwesomeAlert
        show={alertConformCompleteVirtual}
        title=''
        message=''
        messageStyle={{ textAlign: "center" }}
        customView={
          <View
            style={{
              alignItems: "center",
              paddingVertical: 10,
              lineHeight: 21,
              paddingHorizontal: 20,
            }}
          >
            <Box style={{ alignSelf: "center", marginBottom: 20 }} />
            {/* <Image source={require('../../assets/images/box.png')} style={{alignSelf: 'center',width:'35%',height:'80%'}}/> */}
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 16,
                color: "#707070",
                lineHeight: 21,
              }}
            >
              Are you sure you want to accept the order as complete?
            </Text>
          </View>
        }
        showCancelButton={true}
        showConfirmButton={true}
        confirmText='Yes'
        cancelText='No'
        confirmButtonColor={config.primary_color}
        onCancelPressed={() => {
          setAlertConfirmCompleteVirtual(false);
        }}
        onConfirmPressed={() => {
          setAlertConfirmCompleteVirtual(false);
          props.changeGigMilestoneStatus(
            props.route.params.uid,
            props.gigMilestoneDetails[0].uid,
            "completed",
            true
          );
        }}
      />

      <Modal
        visible={props.redoOverlay}
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        onDismiss={() => {
          props.overlayRedoToggle();
        }}
      >
        <RedoOverlay
          classification={props.gigDetails?.classification}
          message={redoDetails}
          onGoBack={data => {
            props.overlayRedoToggle();
          }}
          onMilestoneClose={data => {
            props.overlayRedoToggle();
            props.updateRedo(
              props.route.params.uid,
              redoMilestoneId,
              "requested_modification",
              data
            );
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={deliveryDetailsModel}
        onDismiss={() => {
          setDeliveryDetailsModel(false);
        }}
      >
        <DeliveryDetails
          message={redoDetails}
          onGoBack={data => {
            setDeliveryDetailsModel(false);
          }}
          {...props}
          currentMilestoneId={tempUid}
          onMilestoneClose={(data, img) => {
            // console.log(img);
            setDeliveryDetailsModel(false);
            checkMilestonesAndUpdateStatus(
              props.route.params.uid,
              tempUid,
              tempStatus,
              data,
              img
            );
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={viewDeliveryDetailsModel}
        onDismiss={() => {
          setViewDeliveryDetailsModel(false);
        }}
      >
        <ViewDeliveryDetails
          message={redoDetails}
          onGoBack={data => {
            setViewDeliveryDetailsModel(false);
          }}
          data={tempData[0]}
          imagePath={tempData[1]}
        />
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    height: 30,
    marginRight: 15,
    fontSize: 25,
  },
  dropdown: {
    margin: "5%",
    marginBottom: "2%",
    paddingLeft: "5%",
    flexDirection: "row",
    paddingVertical: "3%",
    overflow: "hidden",
    backgroundColor: "#FFF",
  },

  cardButton: {
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 4,
    backgroundColor: config.primary_color,
    marginRight: 20,
    marginTop: 5,
  },
  helpMainText: {
    fontFamily: "Roboto-Bold",
    fontSize: 14,
    color: "#828282",
    textAlign: "center",
    marginTop: 30,
  },
  helpSubText: {
    fontFamily: "Roboto-Bold",
    fontSize: 15,
    color: "#B33BA7",
    textAlign: "center",
    marginBottom: 50,
  },
  buttonsContainer: {
    marginVertical: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    marginBottom: 20,
  },
});

export default connect(
  state => ({ profile: { ...state.userProfile }, ...state.gigDetails }),
  {
    loadGigDetails,
    scrollToggle,
    changeGigOrderStatus,
    changeGigMilestoneStatus,
    fundFullAmount,
    overlayRedoToggle,
    fundMilestone,
    updateRedo,
    hideAlert,
    refundOrder,
    submitReview,
    resetFloatingData,
    loadProfile,
    setActionPending,
  }
)(GigDetails);
