//import liraries
import React, {Component} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  FlatList,
} from 'react-native'
import config from '../../config'
import ProtectedPaymentsCard from './ProtectedPaymentsCard'

class RejectedTab extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  renderPayment = (item, index) => {
    return (
      <ProtectedPaymentsCard
        {...item}
        color={'#D92E0B'}
        clicked={() => {
          this.props.navigation.navigate('GigDetails', {uid: `${item.uid}`})
        }}
      />
    )
  }
  render () {
    return this.props.rejectedPayments.length == 0?
    <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
      <Text style={{fontFamily:'OpenSans-Regular',color:'#828282'}}>No transactions here yet</Text>
    </View>
    :(
      <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        <View
          style={{alignItems: 'center', paddingBottom: '3%', marginTop: 20}}>
          <FlatList
            style={{width: '90%'}}
            data={this.props.rejectedPayments}
            renderItem={({item, index}) => this.renderPayment(item, index)}
            keyExtractor={(item, index) => index.toString()}
            removeClippedSubviews={true}
            initialNumToRender={20}
            maxToRenderPerBatch={10}
          />
        </View>
      </SafeAreaView>
    )
  }
  clicked = () => {
    this.props.navigation.navigate('PaymentResult')
  }
}

// define your styles
const styles = StyleSheet.create({
  button: {
    color: config.primary_color,
    fontSize: 30,
    marginRight: 20,
  },
})

//make this component available to the app
export default RejectedTab
