//import liraries
import React, { Component, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { checkAuth } from "../state-management/actions/auth.action";
import { NavigationContainer } from "@react-navigation/native";
import AuthStack from "./AuthStack";
import AppStack from "./AppStack";
import { navigationRef } from "./Navigator";
import { AppLoading } from "expo";
import { useFonts } from "expo-font";
import { Platform, View, Image, Dimensions, Text } from "react-native";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import config from "../config";
import logsService from "../services/logs.service";
import LeftPanel from "../components/LeftPanel";

const w = Dimensions.get("window");

const appRoutes = {
  SignUp: { path: "/SignUp/:?/:?" },
  demo: { path: "/demo/:?/:?" },
  Login: { path: "/Login/:?/:?" },
  GetStarted: { path: "/GetStarted/:?/:?" },
  OtpVerify: { path: "/OtpVerify/:?/:?" },
  // Home: {path:"/Home/:?/:?"},
  AllNotifications: { path: "/AllNotifications/:?/:?" },
  PaymentResult: { path: "/PaymentResult/:?/:?" },
  GigDetails: { path: "/PaymentPreview/:?/:?" },
  SaveRecommendation: { path: "recommendation/:id?/:?" },
  MerchantPayment: { path: "/MerchantPayment/:?/:?" },
  // PaymentCheckout: {path:'/PaymentCheckout/:data?/:?'},
  DirectPay: {path: "/DirectPay/:?/:?"},
  UserProfile: {
    path: "/:value/:store?/:storeId?",
  },
  Main: {
    path: "/Main/Home/:?/:?",
  },
}

const authRoutes = {
  SignUp: { path: "/SignUp/:?/:?" },
  demo: { path: "/demo/:?/:?" },
  GetStarted: { path: "/GetStarted/:?/:?" },
  OtpVerify: { path: "/OtpVerify/:?/:?" },
  Home: { path: "/Home/:?/:?" },
  AllNotifications: { path: "/AllNotifications/:?/:?" },
  PaymentResult: { path: "/PaymentResult/:?/:?" },
  PaymentPreview: { path: "/PaymentPreview/:?/:?" },
  MerchantPreview: { path: "/MerchantPayment/:?/:?" },
  DirectPay: {path: "/DirectPay/:?/:?"},
  GuestPaymentCheckout: {path:"/Pay/:?/:?"},
  Login: {
    path: "/Login/:?/:?",
  },
  Recommendation: {
    path: "/recommendation/:id?/:?",
  },
  UserPreview: {
    path: "/:value/:store?/:storeId?",
  },
}


const Navigation = props => {
  let [loaded, error] = useFonts({
    "OpenSans-Bold": require("../assets/fonts/OpenSans-Bold.ttf"),
    "OpenSans-Regular": require("../assets/fonts/OpenSans-Regular.ttf"),
    "OpenSans-SemiBold": require("../assets/fonts/OpenSans-SemiBold.ttf"),
    "Roboto-Bold": require("../assets/fonts/Roboto-Bold.ttf"),
    "Roboto-Regular": require("../assets/fonts/Roboto-Regular.ttf"),
    "Roboto-Medium": require("../assets/fonts/Roboto-Medium.ttf"),
  });
  let [user, setUser] = useState(false);
  let [isUserLoaded, setUserLoaded] = useState(false);
  const [width, setWidth] = useState(w.width);
  const [linking, setLinking] = useState({});
  const routeNameRef = useRef();
  const [routeName,setRouteName] = useState('');

  if (props.error) {
    alert(props.error);
  }


  useEffect(() => {
    const resizeListener = Dimensions.addEventListener("change", () => {
      setWidth(Dimensions.get("window").width);
    });
    props.checkAuth();
    firebase.auth().onAuthStateChanged(
      data => {
        if (data) {
          setUser(true);
          setLinking({
            config: {
              screens: appRoutes
            },
          });
          setUserLoaded(true);
        } else {
          setUser(false);
          setLinking({
            config: {
              screens: authRoutes
            },
          });
          setUserLoaded(true);
        }
      },
      error => {
        setUser(false);
      }
    );
    return () => {
      resizeListener();
    };
  }, []);

  if (!isUserLoaded) {
    return <AppLoading />;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      onReady={() => {
        routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        logsService.logCustomEvent(`screen_${routeNameRef.current}`, {});
        setRouteName(navigationRef.current.getCurrentRoute().name)
        console.log('====================================');
        console.log(navigationRef.current.getCurrentRoute().name);
        console.log('====================================');
      }}
      onStateChange={async state => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;
        if (previousRouteName !== currentRouteName) {
          logsService.logCustomEvent(`screen_${currentRouteName}`, {});
        }
      }}
    >
      {Platform.OS == "web" ? (
        <View style={{ flex: 1 }}>
          {width <= 990 ? (
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {!props.isAuthenticated && !user ? <AuthStack /> : <AppStack />}
              </View>
            </View>
          ) : (
            <View style={{ flex: 1, flexDirection: "row" }}>
              <LeftPanel routeName ={routeName}/>
              <View style={{ flex: 0.7 }}>
                <View
                  style={{ width: "50%", height: "100%", marginLeft: "25%" }}
                >
                  {!props.isAuthenticated && !user ? (
                    <AuthStack />
                  ) : (
                    <AppStack />
                  )}
                </View>
              </View>
            </View>
          )}
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          {!props.isAuthenticated && !user ? <AuthStack /> : <AppStack />}
        </View>
      )}
    </NavigationContainer>
  );
};

export default connect(state => ({ ...state.auth }), { checkAuth })(Navigation);
