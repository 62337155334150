export default {
  primary_color: "#481380",
  dull_color: "#545454",
  danger: "#DB235B",
  colors: [
    "#DB235B",
    "#473BB3",
    "#09C98F",
    "#5956FE",
    "#6E23DB",
    "#481380",
    "#DEAA01",
  ],
  senderID: "776737643024",

  // Local
  // node_api_endpoint: "http://localhost:3000/v1",
  // chat_server: "ws://192.168.31.202:3000",

  // // Test
  // node_api_endpoint: "https://test.api.iamvouched.com/v1",
  // chat_server: "https://test.api.iamvouched.com",
  // env:'sandbox',
  // firebase: {
  //   apiKey: "AIzaSyDXQMcUBRivwPhlPcSRFdNlEPB4N_m2Bqg",
  //   authDomain: "vouch-testz.firebaseapp.com",
  //   databaseURL: "https://vouch-testz.firebaseio.com",
  //   projectId: "vouch-testz",
  //   storageBucket: "vouch-testz.appspot.com",
  //   messagingSenderId: "923127562999",
  //   appId: "1:923127562999:web:d7175c87493b2caabb09f2",
  //   measurementId: "G-M756V1G69V",
  // },
  // prod Sim
  node_api_endpoint: "https://prodtrans.api.iamvouched.com/v1",
  chat_server: "https://prodtrans.api.iamvouched.com",
  env:'sandbox',
  firebase: {
    apiKey: "AIzaSyDKE_PBjQRv0iM93VnanAp4HQ5sgTYBSiU",
    authDomain: "vouch-prod-transfer.firebaseapp.com",
    projectId: "vouch-prod-transfer",
    storageBucket: "vouch-prod-transfer.appspot.com",
    messagingSenderId: "99743990758",
    appId: "1:99743990758:web:1c0dcb87de945c94e04c27",
    measurementId: "G-C0NK6YKY5R"
  },
  // Prod
  // node_api_endpoint: 'https://prod.api.iamvouched.com/v1',
  // chat_server:'https://prod.api.iamvouched.com',
  // env:'prod',
  // firebase:{
  // apiKey: "AIzaSyDeBwHNDjQRxKJj-GYKd9tOeF0QsKoZZ4Y",
  // authDomain: "vout-263411.firebaseapp.com",
  // databaseURL: "https://vout-263411.firebaseio.com",
  // projectId: "vout-263411",
  // storageBucket: "vout-263411.appspot.com",
  // messagingSenderId: "776737643024",
  // appId: "1:776737643024:web:8fbb8c2b43be5aa843c895",
  // measurementId: "G-F24G6QX424",
  // }
};
