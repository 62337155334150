import React, { Component } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Home from "../pages/app/Home";
import Category from "../pages/app/Category";
import BuySellPage from "../pages/app/BuySellPage";
import PaymentDetails from "../pages/app/PaymentDetails";
import MakePayment from "../pages/app/MakePayment";
import RequestPayment from "../pages/app/RequestPayment";
import PaymentResult from "../pages/app/PaymentResult";
import PaymentUser from "../pages/app/PaymentUser";
import ProtectedPayments from "../pages/app/ProtectedPayments";
import WhoIsUsing from "../pages/app/WhoIsUsing";
import AllNotifications from "../pages/app/AllNotifications";
import WhatsApp from "../pages/app/WhatsApp";
import HowVouchWorks from "../pages/app/HowVouchWorks";
// import UserProfile from "../pages/app/UserProfile";
import EditProfile from "../pages/app/EditProfile";
import GigDetails from "../pages/app/GigDetails";
import MyMoney from "../pages/app/MyMoney";
import MyBank from "../pages/app/MyBank";
import LinkBankAccount from "../pages/app/LinkBankAccount";
import LinkUPI from "../pages/app/LinkUPI";
import TransactionDetails from "../pages/app/TransactionDetails";
import PaymentCheckout from "../pages/app/PaymentCheckout";
import PeopleSearch from "../pages/app/PeopleSearch";
import InviteUser from "../pages/app/InviteUser";
import GigInfo from "../pages/app/GigInfo";
import BankTransferDetails from "../pages/app/BankTransferDetails";
import ForSaleDetail from "../pages/app/ForSaleDetail";
import ForSaleBuying from "../pages/app/ForSaleBuying";
import PortfolioDetail from "../pages/app/PortfolioDetail";
import RecommendationDetails from "../pages/app/RecommendationDetails";
import SaveRecommendation from "../pages/app/SaveRecommendation";
import Errorpage from "../pages/app/Errorpage";
import HomeIcon from "../assets/images/home";
import HomeIconSelected from "../assets/images/homeSelected";
import LockedIcon from "../assets/images/locked";
import LockedIconSelected from "../assets/images/lockedSelected";
import RupeeIcon from "../assets/images/rupee";
import RupeeIconSelected from "../assets/images/rupeeSelected";
import ProfileIcon from "../assets/images/profile";
import ProfileIconSelected from "../assets/images/profileSelected";
import Plus from "../assets/images/NewPaymentButton";
import config from "../config";
import { connect } from "react-redux";
import { Dimensions, Platform, Text, View } from "react-native";
import AIcon from "react-native-vector-icons/AntDesign";
import logsService from "../services/logs.service";
import { switchAuth } from "../state-management/actions/auth.action";
import MerchantPayment from "../pages/app/MerchantPayment";
import MerchantPaymentResult from "../pages/app/MerchantPaymentResult";
import UserProfile from "../pages/app/userprofile/mainpage/UserProfile";
import demo from "../pages/app/demo";
import DirectPay from "../pages/auth/DirectPay";
import TermsAndConditions from "../pages/auth/TermsAndConditions";

var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function NewPaymentStack() {
  return <Stack.Navigator headerMode={false}></Stack.Navigator>;
}

function ProfileStack() {
  return (
    <Stack.Navigator headerMode={false}>
      <Stack.Screen name='Profile' component={UserProfile} />
      {/* <Stack.Screen name='Profile' component={newUserProfile} /> */}
    </Stack.Navigator>
  );
}

const TabStack = props => {
  return (
    <Tab.Navigator
      initialRouteName='Home'
      tabBarOptions={{
        activeTintColor: config.primary_color,
        inactiveTintColor: "#747373",
        labelStyle: {
          fontFamily: "Roboto-Regular",
          fontSize: 11,
        },
        style: {
          height: 60,
        },
        tabStyle: {
          // paddingVertical:12
        },
      }}
    >
      <Tab.Screen
        name='Home'
        component={Home}
        options={{
          tabBarLabel: ({ color, focused }) => (
            <View
              style={{
                alignItems: "center",
                height: "100%",
                justifyContent: "space-evenly",
                flex: 1,
              }}
            >
              {focused ? <HomeIconSelected /> : <HomeIcon />}
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  fontFamily: "Roboto-Regular",
                  color: color,
                }}
              >
                Home
              </Text>
            </View>
          ),
        }}
        listeners={({ navigation }) => ({
          tabPress: e => {
            logsService.logCustomEvent("home_tab_clicked", {});
          },
        })}
      />
      <Tab.Screen
        name='Payments'
        component={ProtectedPayments}
        options={{
          tabBarLabel: ({ color, focused }) => (
            <View
              style={{
                alignItems: "center",
                height: "100%",
                justifyContent: "space-evenly",
                flex: 1,
              }}
            >
              {focused ? <LockedIconSelected /> : <LockedIcon />}
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  fontFamily: "Roboto-Regular",
                  color: color,
                }}
              >
                Payments
              </Text>
            </View>
          ),
        }}
        listeners={({ navigation }) => ({
          tabPress: e => {
            if (props.isLoginLater) {
              props.switchAuth();
            } else {
              logsService.logCustomEvent("protected_payments_tab_clicked", {});
            }
          },
        })}
      />
      <Tab.Screen
        name='NewPaymentStack'
        component={NewPaymentStack}
        options={{
          tabBarLabel: ({ color, focused }) => (
            <View
              style={{
                alignItems: "center",
                position: "relative",
                top: isMobile ? -9 : -15,
              }}
            >
              {/* <AIcon name='pluscircle' style={{fontSize:48,color:config.primary_color,backgroundColor:'#FFF'}} /> */}
              <Plus style={{}} />
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  fontFamily: "Roboto-Regular",
                  color: "#747373",
                  marginTop: isMobile ? 4 : 9,
                }}
              >
                Protected Payment
              </Text>
            </View>
          ),
        }}
        labelStyle={{ marginBottom: 100 }}
        listeners={({ navigation }) => ({
          tabPress: e => {
            logsService.logCustomEvent("new_payment_tab_clicked", {});
            e.preventDefault();
            if (props.recentContacts.total_payments_length) {
              props.navigation.navigate("Category", {
                title: "Go ahead, select a\nCategory",
                description:
                  "You can protect payments for Goods and Services by paying through Vouch.",
                flow_code: 0,
              });
            } else navigation.navigate("HowVouchWorks");
          },
        })}
      />
      <Tab.Screen
        name='MyMoney'
        component={MyMoney}
        options={{
          tabBarLabel: ({ color, focused }) => (
            <View
              style={{
                alignItems: "center",
                height: "100%",
                justifyContent: "space-evenly",
                flex: 1,
              }}
            >
              {focused ? <RupeeIconSelected /> : <RupeeIcon />}
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  fontFamily: "Roboto-Regular",
                  color: color,
                }}
              >
                My Money
              </Text>
            </View>
          ),
        }}
        listeners={({ navigation }) => ({
          tabPress: e => {
            if (props.isLoginLater) {
              props.switchAuth();
            } else {
              logsService.logCustomEvent("my_money_tab_clicked", {});
            }
          },
        })}
      />
      <Tab.Screen
        name='ProfileStack'
        component={ProfileStack}
        options={{
          tabBarLabel: ({ color, focused }) => (
            <View
              style={{
                alignItems: "center",
                height: "100%",
                justifyContent: "space-evenly",
                flex: 1,
              }}
            >
              {focused ? <ProfileIconSelected /> : <ProfileIcon />}
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  fontFamily: "Roboto-Regular",
                  color: color,
                }}
              >
                Profile
              </Text>
            </View>
          ),
        }}
        listeners={({ navigation }) => ({
          tabPress: e => {
            if (props.isLoginLater) {
              props.switchAuth();
            } else {
              logsService.logCustomEvent("profile_tab_clicked", {});
              e.preventDefault();
              props.navigation.navigate("ProfileStack", {
                screen: "Profile",
                params: {
                  key: "username",
                  value: props.profile?.userProfile?.username,
                },
              });
            }
          },
        })}
      />
    </Tab.Navigator>
  );
};

const TabStackUpdated = connect(
  state => ({
    ...state.auth,
    profile: { ...state.userProfile },
    recentContacts: { ...state.recentContacts },
  }),
  { switchAuth }
)(TabStack);

function AppStack(props) {
  return (
    <Stack.Navigator headerMode={false}>
      <Stack.Screen name='Main' component={TabStackUpdated} {...props} />
      <Stack.Screen name='Category' component={Category} />
      <Stack.Screen name='BuySellPage' component={BuySellPage} />
      <Stack.Screen name='PaymentDetails' component={PaymentDetails} />
      <Stack.Screen name='MakePayment' component={MakePayment} />
      <Stack.Screen name='RequestPayment' component={RequestPayment} />
      <Stack.Screen name='PaymentResult' component={PaymentResult} />
      <Stack.Screen name='PaymentUser' component={PaymentUser} />
      <Stack.Screen name='WhoIsUsing' component={WhoIsUsing} />
      <Stack.Screen name='AllNotifications' component={AllNotifications} />
      <Stack.Screen name='WhatsApp' component={WhatsApp} />
      <Stack.Screen name='HowVouchWorks' component={HowVouchWorks} />
      <Stack.Screen name='EditProfile' component={EditProfile} />
      <Stack.Screen name='GigDetails' component={GigDetails} />
      <Stack.Screen name='GigInfo' component={GigInfo} />
      <Stack.Screen name='MyBank' component={MyBank} />
      <Stack.Screen name='LinkBankAccount' component={LinkBankAccount} />
      <Stack.Screen name='LinkUPI' component={LinkUPI} />
      <Stack.Screen name='TransactionDetails' component={TransactionDetails} />
      <Stack.Screen name='PaymentCheckout' component={PaymentCheckout} />
      <Stack.Screen name='PeopleSearch' component={PeopleSearch} />
      <Stack.Screen name='InviteUser' component={InviteUser} />
      <Stack.Screen name='demo' component={demo} />
      <Stack.Screen name='DirectPay' component={DirectPay} />
      <Stack.Screen
        name='BankTransferDetails'
        component={BankTransferDetails}
      />
      <Stack.Screen name='ForSaleDetail' component={ForSaleDetail} />
      <Stack.Screen name='ForSaleBuying' component={ForSaleBuying} />
      <Stack.Screen name='PortfolioDetail' component={PortfolioDetail} />
      <Stack.Screen
        name='RecommendationDetails'
        component={RecommendationDetails}
      />
      <Stack.Screen name='SaveRecommendation' component={SaveRecommendation} />
      <Stack.Screen name='Errorpage' component={Errorpage} />
      <Stack.Screen name='UserProfile' component={UserProfile} />
      {/* <Stack.Screen name='NewUserProfile' component={newUserProfile} /> */}
      <Stack.Screen name='MerchantPayment' component={MerchantPayment} />
      <Stack.Screen
        name='MerchantPaymentResult'
        component={MerchantPaymentResult}
      />
      <Stack.Screen name='TermsAndConditions' component={TermsAndConditions} />

    </Stack.Navigator>
  );
}

export default AppStack;
