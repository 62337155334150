import React, { Component } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Login from "../pages/auth/Login";
import GetStarted from "../pages/auth/GetStarted";
import SignUp from "../pages/auth/SignUp";
import OtpVerify from "../pages/auth/OtpVerify";
import TermsAndConditions from "../pages/auth/TermsAndConditions";
import PaymentPreview from "../pages/auth/PaymentPreview";
import UserPreview from "../pages/auth/UserPreview";
import ForSaleDetail from "../pages/auth/ForSaleDetail";
import PortfolioDetail from "../pages/app/PortfolioDetail";
import ForSalePreview from "../pages/auth/ForSalePreview";
import SaveRecommendation from "../pages/auth/SaveRecommendation";
import MerchantPreview from "../pages/auth/MerchantPreview";
import UserProfile from "../pages/app/userprofile/mainpage/UserProfile";
import demo from "../pages/app/demo";
import DirectPay from "../pages/auth/DirectPay";
import GuestPaymentCheckout from "../pages/auth/GuestPaymentCheckout";
import Errorpage from "../pages/app/Errorpage";

const Stack = createStackNavigator();

function AuthStack() {
  return (
    <Stack.Navigator headerMode='none'>
      <Stack.Screen name='GetStarted' component={GetStarted} />
      <Stack.Screen name='Login' component={Login} />
      <Stack.Screen name='SignUp' component={SignUp} />
      <Stack.Screen name='OtpVerify' component={OtpVerify} />
      <Stack.Screen name='TermsAndConditions' component={TermsAndConditions} />
      <Stack.Screen name='PaymentPreview' component={PaymentPreview} />
      <Stack.Screen name='DirectPay' component={DirectPay} />
      <Stack.Screen name='MerchantPreview' component={MerchantPreview} />
      <Stack.Screen name='UserPreview' component={UserPreview} />
      <Stack.Screen name='ForSale' component={ForSaleDetail} />
      <Stack.Screen name='ForSalePreview' component={ForSalePreview} />
      <Stack.Screen name='PortfolioDetail' component={PortfolioDetail} />
      <Stack.Screen name='Recommendation' component={SaveRecommendation} />
      <Stack.Screen name='demo' component={demo} />
      <Stack.Screen name='GuestPaymentCheckout' component={GuestPaymentCheckout} />
      <Stack.Screen name='Errorpage' component={Errorpage} />
    </Stack.Navigator>
  );
}

export default AuthStack;
